import React from 'react'
import styled from 'styled-components'
import { breakpoint, getColor } from '../../shared/theme'

const FieldWrapper = styled.div`
	grid-column: span 12;
	display: flex;
	align-items: flex-start;
	margin-bottom: 20px;

	@media ${breakpoint('mobile')} {
		margin-bottom: 28px;
		grid-column: ${(props) => (props.fullField ? 'span 12' : 'span 6')};
	}

	@media ${breakpoint('tablet')} {
		margin-bottom: 28px;
		align-items: center;
		grid-column: ${(props) => (props.fullField ? 'span 14' : 'span 7')};
	}
`

const Input = styled.input`
	border: 2px solid ${getColor('black')};
	width: 30px;
	height: 30px;
	margin-right: 20px;

	&:checked {
		background: ${getColor('black')};
	}

	@media ${breakpoint('tablet')} {
		width: 40px;
		height: 40px;
		margin-right: 36px;
	}

	@media ${breakpoint('desktop')} {
		width: 54px;
		height: 48px;
		margin-right: 50px;
	}
`

const Label = styled.label`
	padding-top: 0.25em;

	@media ${breakpoint('tablet')} {
		padding-top: 0;
	}
`

const CheckboxField = ({ name }) => {
	const id = name ? name.toLowerCase().replace(/[^A-Z0-9]/gi, '') : false

	if (!id) {
		return null
	}

	return (
		<FieldWrapper>
			<Input type="checkbox" id={id} name={name} value="true" />
			<Label htmlFor={id}>{name}</Label>
		</FieldWrapper>
	)
}

export default CheckboxField
