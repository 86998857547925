import React from 'react'
import styled from 'styled-components'
import { getColor, breakpoint } from '../../shared/theme'

const TextArea = styled.textarea`
	border: 2px solid ${getColor('black')};
	height: 252px;
	width: 100%;
	padding: 10px;

	@media ${breakpoint('tablet')} {
		height: 275px;
	}
`

const TextAreaField = ({ value, onChange }) => {
	return <TextArea onChange={onChange} value={value}></TextArea>
}

export default TextAreaField
