import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { getColor, breakpoint, ease } from '../../shared/theme'
import Arrow from '../../images/select-arrow.svg'

const ErrorMessage = styled.span`
	margin-top: 1em;
	font-size: 10px;
	color: ${getColor('orange')};
	display: none;
	transition: all 200ms ${ease};
`

const FieldWrapper = styled.div`
	grid-column: -1 / 1;
	display: flex;
	flex-direction: column;
	position: relative;

	&:last-child {
		margin-bottom: 0;
	}

	@media ${breakpoint('tablet')} {
		grid-column: ${(props) => (props.fullField ? '-1 / 1' : 'span 5')};
	}

	${(props) =>
		props.type === 'select' &&
		css`
			border-bottom: 2px solid ${getColor('black')};
			padding-bottom: 0.5em;
		`}
`

const Label = styled.label`
	margin-bottom: 24px;
	font-size: 12px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 42px;
		font-size: 14px;
	}

	@media ${breakpoint('desktop')} {
		font-size: 18px;
	}
`

const Input = styled.input`
	box-shadow: none;
	border-bottom: 2px solid ${getColor('black')};
	padding-bottom: 0.5em;
	transition: all 200ms ${ease};
	line-height: 1.65;

	&:not(:placeholder-shown):invalid {
		${(props) =>
			props.touched &&
			css`
				border-color: ${getColor('orange')};

				& + ${ErrorMessage} {
					display: block;
				}
			`}
	}
`

const Select = styled.select`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-image: url(${Arrow});
	background-repeat: no-repeat;
	background-size: 7px;
	line-height: 1.65;
	background-position: right center;
	transform: translateX(-0.2em);
	font-family: ${(props) => props.theme.fonts.goodSans};
`

const Option = styled.option`
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding: 0 0;
	line-height: 100%;
	font-family: ${(props) => props.theme.fonts.goodSans};
`

const InputField = ({
	fullField,
	label,
	name,
	value,
	onChange,
	required,
	type,
	options,
}) => {
	const [hasTouched, setTouch] = useState(false)
	return (
		<FieldWrapper fullField={fullField} type={options ? 'select' : 'text'}>
			<Label>
				{label}
				{required ? '*' : null}
			</Label>
			{options ? (
				<>
					<Select name={name} value={value} onChange={onChange}>
						<Option defaultValue="">Please select</Option>
						{options.map((option, index) => (
							<Option value={option} key={index}>
								{option}
							</Option>
						))}
					</Select>
				</>
			) : (
				<>
					<Input
						type={type}
						name={name}
						value={value}
						onChange={onChange}
						onBlur={() => {
							setTouch(true)
						}}
						touched={hasTouched}
						required={required}
					/>
					{required && <ErrorMessage>This field is required</ErrorMessage>}
				</>
			)}
		</FieldWrapper>
	)
}

export default InputField
