import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import styled, { css } from 'styled-components'
import InnerWrapper from '../../elements/innerWrapper'
import H3 from '../../elements/typography/h3'
import InputField from '../../components/inputField'
import { breakpoint, getColor } from '../../shared/theme'
import Grid, { Wrapper } from '../../elements/grid'
import CheckboxField from '../../components/checkboxField'
import TextAreaField from '../../components/textAreaField'
import SubmitButton from '../../components/submitButton'
import SectionTransition from '../../elements/activeSectionDefault'

const ContactFormWrapper = styled(SectionTransition)`
	padding: 48px 0 60px;

	@media ${breakpoint('tablet')} {
		padding: 180px 0 280px;
	}
`

const gridStyle = css`
	grid-column: -1 / 1;

	@media ${breakpoint('laptop')} {
		grid-column: 3 / span 10;
	}
`

const FormWrapper = styled(Wrapper)``

const InputFieldWrapper = styled(Wrapper)`
	${gridStyle};
	row-gap: 20px;
	margin-bottom: 80px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 136px;
		row-gap: 24px;
	}

	@media ${breakpoint('desktop')} {
		margin-bottom: 180px;
	}
`

const Title = styled(H3)`
	grid-column: span 5;
	margin-bottom: 36px;
`

const SecondaryTitle = styled(H3)`
	font-size: 14px;
	font-weight: 800;
	margin-bottom: 15px;
	letter-spacing: 0.5px;

	@media ${breakpoint('tablet')} {
		grid-column: span 5;
		margin-bottom: 40px;
		font-size: 24px;
		font-weight: 400;
	}

	@media ${breakpoint('desktop')} {
		font-size: 28px;
	}
`

const Note = styled.div`
	grid-column: 14;
	font-size: 12px;

	span {
		font-style: italic;
		color: ${getColor('greyMid')};
		font-size: 8px;

		@media ${breakpoint('tablet')} {
			font-size: 18px;
		}
	}

	@media ${breakpoint('tablet')} {
		font-size: 18px;
		grid-column: span 5;
	}
`

const HiddenInput = styled.input``

const Form = styled.form`
	grid-column: span 14;
`

const CheckboxFieldWrapper = styled.div`
	${gridStyle};
	display: flex;
	flex-direction: column;
	margin-bottom: 76px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 136px;
	}

	@media ${breakpoint('desktop')} {
		margin-bottom: 180px;
	}
`

const TextAreaFieldWrapper = styled.div`
	${gridStyle};
	margin-bottom: 15px;

	@media ${breakpoint('tablet')} {
		margin-bottom: 40px;
	}
`

const HeaderInner = styled(Wrapper)`
	${gridStyle};
`

const ContactForm = ({ isActive }) => {
	const [firstName, setFirstName] = useState('')
	const [lastName, setLastName] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [country, setCountry] = useState('')
	const [influencer, setInfluencer] = useState('')
	const [brand, setBrand] = useState('')
	const [instagram, setInstagram] = useState('')
	const [budget, setBudget] = useState('')
	const [moreInfo, setMoreInfo] = useState('')

	const contactForm = useRef(null)

	const handleSubmit = (e) => {
		e.preventDefault()

		let myForm = contactForm.current
		let formData = new FormData(myForm)

		fetch('/', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: new URLSearchParams(formData).toString(),
		})
			.then(() => navigate('/thank-you/'))
			.catch((error) => alert(error))
	}

	const handleFirstNameChange = (e) => {
		setFirstName(e.target.value)
	}

	const handleLastNameChange = (e) => {
		setLastName(e.target.value)
	}

	const handleEmailChange = (e) => {
		setEmail(e.target.value)
	}

	const handlePhoneChange = (e) => {
		setPhone(e.target.value)
	}

	const handleCountryChange = (e) => {
		setCountry(e.target.value)
	}

	const handleInfluencerChange = (e) => {
		setInfluencer(e.target.value)
	}

	const handleInstagramChange = (e) => {
		setInstagram(e.target.value)
	}

	const handleBrandChange = (e) => {
		setBrand(e.target.value)
	}

	const handleBudgetChange = (e) => {
		setBudget(e.target.value)
	}

	const handleMoreInfoChange = (e) => {
		setMoreInfo(e.target.value)
	}

	return (
		<ContactFormWrapper isActive={isActive}>
			<InnerWrapper>
				<FormWrapper>
					<Form
						name="contact"
						method="post"
						data-netlify="true"
						data-netlify-honeypot="bot-field"
						ref={contactForm}
						onSubmit={handleSubmit}
					>
						<Grid>
							<HeaderInner desktopColumns="10">
								<HiddenInput type="hidden" name="form-name" value="contact" />
								<Title>Enquiries:</Title>
								<Note>
									*<span>Required Fields</span>
								</Note>
							</HeaderInner>

							<InputFieldWrapper desktopColumns="10">
								<InputField
									type="text"
									label="First Name"
									name="first name"
									value={firstName}
									onChange={handleFirstNameChange}
									required
								/>
								<InputField
									type="text"
									label="Last Name"
									name="last name"
									value={lastName}
									onChange={handleLastNameChange}
									required
								/>
								<InputField
									fullField
									type="email"
									label="Email"
									name="email"
									value={email}
									onChange={handleEmailChange}
									required
								/>
								<InputField
									fullField
									type="phone"
									label="Phone"
									name="phone"
									value={phone}
									onChange={handlePhoneChange}
									required
								/>
								<InputField
									type="text"
									label="Country"
									name="country"
									value={country}
									onChange={handleCountryChange}
									required
								/>
								<InputField
									type="select"
									options={['Yes', 'No']}
									label="Are you an influencer?"
									name="influencer"
									value={influencer}
									onChange={handleInfluencerChange}
									required
								/>
								<InputField
									fullField
									type="text"
									label="What's your Instagram handle?"
									name="instagram"
									value={instagram}
									onChange={handleInstagramChange}
								/>
								<InputField
									type="select"
									options={['$30-50k', '$50-100k', '$100-200k']}
									label="What is your budget?"
									name="budget"
									value={budget}
									onChange={handleBudgetChange}
									required
								/>
								<InputField
									type="select"
									options={['Yes', 'No']}
									label="Do you have an existing brand?"
									name="brand"
									value={brand}
									onChange={handleBrandChange}
									required
								/>
							</InputFieldWrapper>

							<TextAreaFieldWrapper>
								<SecondaryTitle>Tell us a little more:*</SecondaryTitle>
								<TextAreaField
									onChange={handleMoreInfoChange}
									value={moreInfo}
								/>
							</TextAreaFieldWrapper>

							<SubmitButton />
						</Grid>
					</Form>
				</FormWrapper>
			</InnerWrapper>
		</ContactFormWrapper>
	)
}

ContactForm.propTypes = {
	isActive: PropTypes.bool,
}

ContactForm.defaultProps = {
	isActive: true,
}

export default ContactForm
