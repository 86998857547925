import React from 'react'
import styled from 'styled-components'
import { getColor, breakpoint } from '../../shared/theme'

const Button = styled.button`
	background-color: ${getColor('black')};
	border: 2px solid ${getColor('black')};
	color: ${getColor('white')};
	font-weight: 800;
	text-transform: uppercase;
	text-align: left;
	position: relative;
	grid-column: -1 / 1;
	font-size: 8px;
	padding: 23px 0 24px 21px;

	@media ${breakpoint('mobile')} {
		max-width: 275px;
	}

	@media ${breakpoint('tablet')} {
		padding: 30px 0px 29px 64px;
		font-size: 12px;
	}

	@media ${breakpoint('laptop')} {
		grid-column: 3 / span 10;
	}

	svg {
		position: absolute;
		right: 30px;
		top: 22px;
		width: 11px;
		height: 11px;

		polyline,
		line {
			stroke-width: 2;
			stroke: ${getColor('white')};
			transition: stroke ${(props) => props.theme.transitionSpeed.default};
		}

		@media ${breakpoint('tablet')} {
			top: 28px;
			width: 16px;
			height: 16px;
		}
	}

	&:hover {
		background-color: transparent;
		color: ${getColor('black')};

		svg {
			polyline,
			line {
				stroke: ${getColor('black')};
			}
		}

		transition: all ${(props) => props.theme.transitionSpeed.default};
	}
`

const SubmitButton = () => {
	return (
		<Button type="submit">
			Submit
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.4 18.5">
				<polyline
					fill="none"
					points="7.6,17.1 15,9.3 
					7.6,1.4 "
				/>
				<line fill="none" x1="15" y1="9.3" x2="0" y2="9.3" />
			</svg>
		</Button>
	)
}

export default SubmitButton
